<template>
	<div class="page-content">
		<b-row>
			<b-col md="10">
				<page-breadcrumb title="Reward Management" class="mb-3" />
			</b-col>
		</b-row>
		<data-create-popup title="Create Reward" :schema="create_schema" :default-data="{ image: null, name: null, type: null }"
			:create-data-fn="createItem" @create="$refs.group_list.getList()" 
		/>
		<data-table-ssr id="group_list" ref="group_list" :columns="fields" :get-list-fn="getList" :to-edit-fn="toEditPage" />
	</div>
</template>
  
<script>
import service from "../service";
const create_schema = [
	{
		cols: 4,
		fields: [
			{ label: 'Image', field: 'image', input_type: 'image' },
		]
	},
	{
		cols: 8,
		fields: [
			{ label: 'Name', field: 'name', validate: { required: true } },
			{ label: 'Type', field: 'type', validate: { required: true } },
		]
	}
];

const fields = [
	{ label: 'Image', field: 'image', input_type: 'image' },
	{ label: 'Name', field: 'name' },
	{ label: 'Type', field: 'type' },
];
export default {

	data() {
		return {
			courses: [],
			create_schema,
			fields,
		};
	},

	created() {
	},

	methods: {
		async getList({ limit, page, query }) {
			let response_data = await service.getList({
				query: JSON.stringify({ ...query }),
				page, limit
			});
			let list = [], total = 0;
			if (response_data) {
				list = response_data.list;
				total = response_data.total;
				this.total = total;
			}
			return { list, total };
		},
		async createItem(data) {
			let new_item = await service.create({ ...data });
			return new_item;
		},
		toEditPage({ _id }) {
			this.$router.push({ name: 'reward-detail', params: { id: _id } });
		},
	},
};
</script>
  